import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Fade from "react-reveal/Fade"
import localize from "../components/localize"
import PortableText from "../components/portableText"
import makeCarousel from "react-reveal/makeCarousel"
import tw from "twin.macro"
import styled from "@emotion/styled"
import { useIntl} from "gatsby-plugin-intl"

const CarouselUI = ({ children }) => <Container>{children}</Container>
const Carousel = makeCarousel(CarouselUI)

export const query = graphql`
  {
    allSanityBiography {
      edges {
        node {
          title {
            _type
            es
            pt
            en
          }
          _rawBody
          slug {
            current
          }
        }
      }
    }
  }
`

const AboutPage = ({ data }) => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "about" })} />
      <div className="flex items-center justify-center min-h-screen pt-20 bg-gray-900">
        {data.allSanityBiography.edges.map(({ node: bio }) => (
          <div className="relative z-20 px-12 py-6 prose bg-gray-100">
            <h1 tw="pt-6 pb-0 text-5xl font-mono text-left w-full ">
              <Fade>{bio.title}</Fade>
            </h1>
            <div tw="prose text-gray-900 text-lg text-left font-mono font-bold">
              <Fade>
                {bio._rawBody && <PortableText blocks={bio._rawBody} />}
              </Fade>
            </div>
          </div>
        ))}
      </div>
      <div className="absolute inset-0 z-10 opacity-20">
        <Carousel
          defaultWait={3000}
          maxTurns={100} /*wait for 1000 milliseconds*/
        >
          <Fade>
            <img
              alt="contacto"
              src="https://images.unsplash.com/photo-1533699224246-6dc3b3ed3304?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1489&q=80"
            />
          </Fade>
          <Fade>
            <img
              alt="contacto"
              src="https://images.unsplash.com/photo-1509565118126-4f0ff5aec05d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1419&q=80"
            />
          </Fade>
          <Fade>
            <img
              alt="contacto"
              src="https://images.unsplash.com/photo-1523509080324-9183f313dc50?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1782&q=80"
            />
          </Fade>
        </Carousel>
      </div>
    </Layout>
  )
}
export default localize(AboutPage)

const Container = styled.div`
  ${tw`relative z-10 flex items-center justify-center w-full mx-auto overflow-hidden text-base md:block `}
  height: 100vh;

  .gatsby-image-wrapper {
    ${tw`h-screen `}
  }

  img {
    ${tw`object-cover w-full h-screen md:h-auto`}
  }
`
